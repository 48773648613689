<template>
  <filter-form
    ref="filterControl"
    title="Filter"
    @on-apply-filters="onApplyFilters()"
    @on-reset-filters="onResetFilters()"
    @on-toggle-filters="onToggleFilters($event)"
  >
    <template v-slot:filter-controls>
      <delivery-filter-form v-if="isDeliveryRoute" />

      <internal-filter-form v-if="isInternalRoute" />

      <programmatic-filter-form v-if="isProgrammaticRoute" />
    </template>
  </filter-form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import render from 'src/utils/render'

import FilterForm from './FilterForm'
import DeliveryFilterForm from './DeliveryFilterForm'
import InternalFilterForm from './InternalFilterForm'
import ProgrammaticFilterForm from './ProgrammaticFilterForm'
// import SavedFilterForm from './SavedFilterForm'

const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  name: 'FilterBar',
  components: {
    DeliveryFilterForm,
    FilterForm,
    InternalFilterForm,
    ProgrammaticFilterForm,
    // SavedFilterForm,
  },
  data() {
    return {
      showSavedFilterForm: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentItems',
      'currentSummary',
      'isDeliveryRoute',
      'isInternalRoute',
      'isProgrammaticRoute',
      'reportKey',
    ]),
    ...mapState({
      displayAdvancedTools: (state) => state.displayAdvancedTools,
      items: (state) => state.items,
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
      subId: (state) => state.subId,
    }),
  },
  methods: {
    async onApplyFilters() {
      const {
        ensureViewIsUpdatedAndRenderedImmediately: renderImmediately,
      } = render
      const { currentItems, reportId, reportKey, reportName, subId } = this
      const report = { items: currentItems, reportId, reportKey, reportName, subId }

      this.$store.commit('tools/isCurrentlyGrouping', true)
      this.$store.commit('tools/isCurrentlyMerging', true)

      const data = this.$store.dispatch('tools/applyFilters', report)

      return renderImmediately(async () => {
        await data

        this.$emit('on-apply-filters')
      })
    },
    async onResetFilters() {
      const {
        ensureViewIsUpdatedAndRenderedImmediately: renderImmediately,
      } = render
      const data = this.$store.dispatch('tools/resetFilters')

      return renderImmediately(async () => {
        await data

        this.$emit('on-reset-filters')
      })
    },
    async onToggleFilters(isExpanded) {
      if (!isExpanded) {
        this.$store.commit('tools/isCurrentlyUpdating', false)
      }

      await this.$store.dispatch('tools/displayFilter', isExpanded)

      this.$emit('on-toggle-filters', isExpanded)
    },
  },
}
</script>

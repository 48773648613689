<template>
  <v-combobox
    ref="textInput"
    v-model="search"
    clearable
    chips
    deletable-chips
    height="auto"
    :items="options"
    :item-text="text"
    :item-value="value"
    :label="label"
    multiple
    return-object
    :search-input.sync="userInput"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content v-if="!editing || !editing.item">
          <v-col class="caption coolgray--text">
            <v-row class="align-center">
              <div class="font-weight-medium pr-1">
                Hint: Add a Custom Value.
              </div>

              <div>
                <span v-if="!userInput">Type and Press </span>
                <span v-else>Press </span>
                <span>Enter</span>
                <span
                  v-if="customOption"
                >
                  or click the
                  <span class="plus-sign">+</span>
                  inside the value below.
                </span>
              </div>
            </v-row>

            <v-row>
              <v-chip
                v-if="customOption"
                :key="customOption.value"
                class="mt-3"
                color="offwhite"
                label
                small
              >
                <v-row class="px-2 align-center">
                  <v-col class="operator-button pr-2">
                    <v-row>
                      <div
                        :class="`cursor-pointer font-weight-bold ${getOperatorColor({ item: customOption })}--text`"
                        @click="toggleOperator({ item: customOption })"
                      >
                        {{ getOperatorLabel(customOption) }}
                      </div>
                    </v-row>
                  </v-col>

                  <v-col
                    class="px-2"
                  >
                    <div v-if="customOption.text.length > maxCharCount">
                      {{ customOption.text.substring(0, maxCharCount) }}...
                    </div>

                    <div v-else>
                      <span>{{ customOption.text }}</span>
                    </div>
                  </v-col>

                  <v-col
                    class="close-button px-2"
                  >
                    <v-row class="justify-center">
                      <v-divider vertical />

                      <div>
                        <v-icon
                          small
                          class="pl-1"
                          color="midgray"
                          @click="selectItem({ item: customOption })"
                        >
                          add
                        </v-icon>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </v-chip>
            </v-row>
          </v-col>
        </v-list-item-content>

        <v-list-item-content
          v-else
        >
          <v-row class="caption coolgray--text mb-3 ml-2 align-center">
            <div class="font-weight-medium pr-1">
              Edit the Selected Value.
            </div>

            <div>
              Press Enter or click Update to submit.
            </div>
          </v-row>

          <v-col
            class="px-5 pt-0"
            transition="slide-y-transition"
          >
            <v-row>
              <v-text-field
                v-model="editing.item.value"
                autofocus
                dense
                flat
                height="25px"
                hide-details
                solo
                @keyup.esc="cancelEdit"
                @keyup.enter="updateItem"
              />
            </v-row>

            <v-row class="mt-3">
              <v-spacer />

              <v-btn
                small
                text
                class="ml-2 primary--text"
                color="primary"
                :disabled="isSubmittingUpdate"
                name="updateItem"
                @click="cancelEdit"
              >
                Cancel
              </v-btn>

              <v-btn
                small
                class="ml-2 white--text"
                color="primary"
                :loading="isSubmittingUpdate"
                name="updateItem"
                @click="updateItem"
              >
                Update
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template
      v-if="['boolean', 'strict', 'textual'].includes(type)"
      v-slot:prepend-item
    >
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-list-item-title class="cursor-pointer align-center">
            <v-row
              class="mx-3"
            >
              <v-icon
                :class="(hasSelectedAll.include && 'primary--text') || 'midgray--text'"
                @click="toggleSelectAll('include', 'select')"
              >
                {{ (hasSelectedAll && 'check_box') || 'check_box_outline_blank' }}
              </v-icon>

              <v-icon
                :class="(hasSelectedAll.exclude && 'toasted--text') || 'midgray--text'"
                @click="toggleSelectAll('exclude', 'select')"
              >
                {{ (hasSelectedAll && 'indeterminate_check_box') || 'check_box_outline_blank' }}
              </v-icon>

              <v-col
                class="coolgray--text font-weight-medium text-wrap body-2 break-word"
                @click="toggleSelectAll('include', 'select')"
              >
                <span>Select All</span>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content class="py-0">
          <v-list-item-title class="cursor-pointer align-center">
            <v-row
              class="mx-3"
            >
              <v-icon
                color="midgray"
                @click="toggleSelectAll('include', 'toggle')"
              >
                {{ (hasSelectedAll && 'check_box') || 'check_box_outline_blank' }}
              </v-icon>

              <v-icon
                color="midgray"
                @click="toggleSelectAll('exclude', 'toggle')"
              >
                {{ (hasSelectedAll && 'indeterminate_check_box') || 'check_box_outline_blank' }}
              </v-icon>

              <v-col
                class="coolgray--text font-weight-medium text-wrap body-2 break-word"
                @click="toggleSelectAll('include', 'toggle')"
              >
                <span>Toggle All</span>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mt-2" />
    </template>

    <template v-slot:item="option">
      <v-list-item
        @on="option.on"
      >
        <v-list-item-content class="py-0">
          <v-list-item-title class="cursor-pointer align-center">
            <v-row
              v-if="['boolean', 'strict', 'textual'].includes(type)"
              class="mx-3"
            >
              <v-icon
                :color="getOptionColor(operatorsInclude, option)"
                @click="selectItem(option, defaultOperatorValue)"
              >
                check_box
              </v-icon>

              <v-icon
                :color="getOptionColor(operatorsExclude, option)"
                @click="selectItem(option, defaultOperatorValueNext)"
              >
                indeterminate_check_box
              </v-icon>

              <v-col
                class="coolgray--text font-weight-medium text-wrap body-2 break-word"
                @click="selectItem(option, defaultOperatorValue)"
              >
                <span>{{ option.item[text] }}</span>
              </v-col>
            </v-row>

            <v-row
              v-else-if="type === 'indicator'"
              class="d-inline-flex"
            >
              <v-col
                :class="`${option.selected ? 'primary' : 'coolgray'}--text font-weight-medium text-wrap body-2 py-0`"
                :ripple="true"
                @click="selectSavedItem(option)"
              >
                <span>{{ option.item[text] }}</span>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:messages="{ message }">
      <div>
        {{ message }}
      </div>
    </template>

    <template v-slot:selection="option">
      <v-chip
        :key="option.item[value]"
        v-bind="option.attrs"
        color="offwhite"
        :disabled="option.disabled"
        :input-value="option.selected"
        label
        small
      >
        <v-row class="px-2 align-center">
          <v-col class="operator-button pr-2 mt-1">
            <v-row>
              <div
                :class="`cursor-pointer font-weight-bold ${getOperatorColor(option)}--text`"
                @click="toggleOperator(option)"
              >
                {{ getOperatorLabel(option.item) }}
              </div>
            </v-row>
          </v-col>

          <v-col
            class="px-2 py-0"
          >
            <div
              v-if="getText(option.item).length > maxCharCount"
              @click="editItem(option)"
            >
              {{ getText(option.item).substring(0, maxCharCount) }}...
            </div>

            <div
              v-else
              @click="editItem(option)"
            >
              {{ getText(option.item) }}
            </div>
          </v-col>

          <v-col class="close-button px-2">
            <v-row class="justify-center">
              <v-divider vertical />

              <div>
                <v-icon
                  small
                  class="pl-1"
                  color="midgray"
                  @click="selectItem(option)"
                >
                  close
                </v-icon>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import operators from 'src/utils/operators'

const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  name: 'TextInput',
  props: {
    filterKey: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    text: {
      type: String,
      default: 'text',
    },
    type: {
      type: String,
      default: 'textual',
    },
    value: {
      type: String,
      default: 'value',
    },
  },
  data() {
    return {
      maxCharCount: 15,
      maxChipCount: 2,
      userInput: '',
      userInputOperator: null,
      editing: {},
      editingIndex: null,
      isSubmittingUpdate: null,
      isUpdateSuccessful: null,
      edited: false,
      operatorsExclude: ['neq', 'excl', 'not'],
      operatorsInclude: ['eq', 'incl', 'is'],
      selected: { include: [], exclude: [] },
    }
  },
  computed: {
    ...mapGetters(['reportKey']),
    ...mapState({
      filterData: (state) => state.filterData,
      reportName: (state) => state.reportName,
    }),
    currentOperators() {
      return operators[this.type].reduce((objOperators, currentOperator) => {
        objOperators[currentOperator.value] = currentOperator

        return objOperators
      }, {})
    },
    defaultOperatorValue() {
      const { type } = this

      return operators.defaults.next[type]
    },
    defaultOperatorValueNext() {
      const { currentOperators, defaultOperatorValue, type } = this

      return currentOperators[defaultOperatorValue].next[type]
    },
    hasHiddenValue() {
      return this.text !== this.value
    },
    search: {
      get() {
        const { filterData, filterKey, reportKey } = this

        return filterData[reportKey][filterKey]
      },
      set(newModel) {
        const currentUserInput = newModel[newModel.length - 1]

        if (typeof currentUserInput === 'string') {
          const { customOption } = this

          return this.selectItem({ item: customOption })
        }

        return this.selectItem({ item: currentUserInput })
      },
    },
    searchOperator: {
      get() {
        const { type } = this

        if (!this.userInput) {
          return operators.defaults.next[type]
        }

        return this.userInputOperator || operators.defaults.next[type]
      },
      set(newValue) {
        const { type, userInput, userInputOperator } = this

        if (!userInput && !userInputOperator) {
          this.userInputOperator = operators.defaults.next[type]
        } else {
          this.userInputOperator = newValue
        }
      },
    },
    customOption: {
      get() {
        const { searchOperator, userInput } = this
        const invalid = !userInput && userInput !== false && userInput !== 0

        if (invalid) return ''

        if (typeof userInput !== 'string') return userInput

        return {
          operator: searchOperator,
          text: userInput,
          value: userInput,
        }
      },
      set(newValue) {
        if (typeof newValue === 'string') {
          this.userInput = newValue
        } else {
          this.userInput = newValue.value
          this.userInputOperator = newValue.operator
        }

        return newValue
      },
    },
    hasSelectedAll() {
      const { operatorsExclude, operatorsInclude, options } = this
      const optionsCount = options.length
      const hasSelectedAll = () => {
        const optionsIncluded = () => this.search.filter((selection) => operatorsInclude.includes(selection.operator))
        const optionsExcluded = () => this.search.filter((selection) => operatorsExclude.includes(selection.operator))

        return {
          include: optionsIncluded().length === optionsCount,
          exclude: optionsExcluded().length === optionsCount,
        }
      }

      return (!optionsCount && { include: false, exclude: false }) || hasSelectedAll()
    },
    hasSelectedSome() {
      return this.search.length > 0 && !this.hasSelectedAll
    },
  },
  methods: {
    getOperatorLabel(item) {
      // if (typeof item === 'string') return item

      const { currentOperators } = this
      // cosnt { defaults } = operators
      const { operator } = item

      return currentOperators[operator].label
    },
    getText(item) {
      const errorMessage = 'Error'
      const value = `${item.text || item.value}`

      return value || errorMessage
    },
    selectItem(option, operator, forcedState) {
      const { filterData, filterKey, reportKey } = this
      const { item } = option
      const selection = this.isSelected(option)

      if (selection && (!forcedState || forcedState === 'off')) {
        const isMatch = (opt) => opt.value === selection.value
        const index = filterData[reportKey][filterKey].findIndex(isMatch)

        if (operator && selection.operator !== operator) {
          if (operator) {
            selection.operator = operator
          }

          filterData[reportKey][filterKey].splice(index, 1, selection)
        } else {
          filterData[reportKey][filterKey].splice(index, 1)
        }
      } else if (!selection) {
        if (operator) {
          item.operator = operator
        }

        filterData[reportKey][filterKey].push(item)
      }

      this.userInput = ''
    },
    selectSavedItem(option) {
      const { search } = this
      const { item } = option
      const { parameters } = item

      parameters.forEach((savedOption) => {
        const { operator, value } = savedOption
        const unselectPrevious = (comparator) => {
          const currentOption = search.find(
            (opt) => opt.operator === comparator,
          )

          if (currentOption) this.selectItem({ item: currentOption })
        }

        if (Array.isArray(value)) {
          value.forEach((val) => {
            unselectPrevious(val.operator)

            this.selectItem({ item: val })
          })
        } else {
          unselectPrevious(operator)

          this.selectItem({ item: savedOption })
        }
      })
    },
    cancelEdit() {
      this.editing = null
      this.editingIndex = null
      this.isSubmittingUpdate = false
      this.isUpdateSuccessful = null
    },
    editItem(option) {
      const { filterData, filterKey, reportKey } = this
      const selection = this.isSelected(option)
      const isMatch = (opt) => opt.value === selection.value
      const index = filterData[reportKey][filterKey].findIndex(isMatch)

      this.isUpdateSuccessful = null
      this.isSubmittingUpdate = false

      this.editing = { ...option }
      this.editingIndex = index
    },
    updateItem() {
      this.isSubmittingUpdate = true
      this.isUpdateSuccessful = null

      const { editing, editingIndex, filterData, filterKey, reportKey } = this

      this.editing.item.text = editing.item.value

      filterData[reportKey][filterKey].splice(editingIndex, 1, editing.item)

      this.isSubmittingUpdate = false
      this.isUpdateSuccessful = true
      this.edited = true

      setTimeout(() => {
        this.cancelEdit()
      }, 200)
    },

    toggleOperator(option) {
      const { currentOperators, type } = this
      const { item } = option
      const { operator } = item
      const isSelected = this.isSelected(option)

      const { [operator]: config } = currentOperators
      const { next } = config

      if (isSelected) {
        this.selectItem(option, next[type])
      } else {
        this.customOption = { ...item, ...{ operator: next[type] } }
      }
    },
    isSelected(option) {
      const { search = [] } = this
      const { item } = option

      return search.find((opt) => opt.value === item.value)
    },
    getOptionColor(operatorValues, option) {
      const { currentOperators } = this
      const { custom, defaults } = operators
      const { item: { operator } } = option
      const { color: operatorColor } = operator === 'custom'
        ? custom
        : currentOperators[operator]

      const isSelected = this.isSelected(option)
      const matches = operatorValues.includes(operator)

      return isSelected && matches ? operatorColor : defaults.color
    },
    getOperatorColor(option) {
      const { item: { operator } } = option
      const { currentOperators } = this
      const { defaults } = operators
      const { color } = currentOperators[operator] || defaults

      return color
    },

    toggleSelectAll(includeOrExclude, selectOrToggle) {
      const operatorsGroupInverse = {
        include: 'exclude',
        exclude: 'include',
      }
      const operatorsExclude = {
        boolean: 'not',
        strict: 'neq',
        textual: 'excl',
      }
      const operatorsInclude = {
        boolean: 'is',
        strict: 'eq',
        textual: 'incl',
      }
      const operator = {
        exclude: () => operatorsExclude[this.type] || 'neq',
        include: () => operatorsInclude[this.type] || 'eq',
      }
      const setOperatorOnItem = (getOperator, forcedState) => {
        this.options.forEach((item) => {
          this.selectItem({ item }, getOperator(), forcedState)
        })
      }
      const invertIncludeOrExclude = operatorsGroupInverse[includeOrExclude]
      const hasSelectedAllInverse = this.hasSelectedAll[invertIncludeOrExclude]
      const hasSelectedAll = this.hasSelectedAll[includeOrExclude]
      const isToggling = selectOrToggle === 'toggle'

      const selectAll = () => {
        setOperatorOnItem(operator[includeOrExclude], 'on')
      }
      const unselectAll = () => {
        setOperatorOnItem(operator[includeOrExclude], 'off')
      }
      const toggleAll = () => {
        setOperatorOnItem(operator[includeOrExclude], false)
      }

      if (isToggling || hasSelectedAllInverse) {
        toggleAll()
      } else if (hasSelectedAll) {
        unselectAll()
      } else {
        selectAll()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.v-list {
  width: 530px;
}

.v-list-item {
  min-height: 40px !important;
}

.text-input-value {
  white-space: normal;
}

.cursor-pointer {
  &:hover {
    color: $color-monochrome-blue;
  }
}

</style>

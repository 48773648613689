<template>
  <v-container
    class="delivery-table-filter px-3"
    fluid
  >
    <v-row class="mt-1">
      <v-col>
        <v-row>
          <v-card-subtitle
            class="subtitle-1 font-weight-medium darkestblue--text"
          >
            <merge-tool />
          </v-card-subtitle>
        </v-row>

        <v-row class="px-4">
          <v-select
            v-model="mergedBy"
            dense
            :items="mergingOptions[reportKey]"
            label="Property"
            multiple
          />
        </v-row>
      </v-col>
    </v-row>

    <v-divider class="mt-6" />

    <v-row class="mt-3">
      <v-col>
        <v-row>
          <v-card-subtitle
            class="subtitle-1 font-weight-medium darkestblue--text"
          >
            Group
          </v-card-subtitle>
        </v-row>

        <v-row class="px-4">
          <v-select
            v-model="groupedBy"
            dense
            :items="groupingOptions[reportKey]"
            label="Property"
            multiple
          />
        </v-row>
      </v-col>
    </v-row>

    <v-divider class="mt-6" />

    <v-row>
      <v-col>
        <v-row>
          <v-card-subtitle
            class="subtitle-1 font-weight-medium darkestblue--text mt-6"
          >
            Filter
          </v-card-subtitle>
        </v-row>

        <health-adx-filter-form v-if="isAdXHealthReport" />
        <health-network-filter-form v-else-if="isNetworkHealthReport" />
        <yield-filter-form v-else-if="isYieldPartnerReport" />

        <v-container v-else>
          <v-row>
            <v-col>
              Choose a report to view filter options.
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import PATHS from 'src/router/paths'

import MergeTool from 'src/tools/components/filter/MergeTool'
import HealthAdxFilterForm from '@/tools/components/filter/HealthAdxFilterForm'
import HealthNetworkFilterForm from '@/tools/components/filter/HealthNetworkFilterForm'
import YieldFilterForm from '@/tools/components/filter/YieldFilterForm'

const { mapGetters, mapState } = createNamespacedHelpers('tools')
const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')

export default {
  name: 'ProgrammaticFilterForm',
  components: {
    HealthAdxFilterForm,
    HealthNetworkFilterForm,
    MergeTool,
    YieldFilterForm,
  },
  computed: {
    ...mapAuthGetters([
      'isUserInternalAdmin',
    ]),
    ...mapGetters([
      'reportKey',

      'currentGroupByProperties',
      'currentMergeByProperties',
    ]),
    ...mapState({
      booleanOptions: (state) => state.booleanOptions,
      filterData: (state) => state.filterData,

      groupingOptions: (state) => state.groupingOptions,
      groupByProperties: (state) => state.groupByProperties,
      grouping: (state) => state.grouping,

      mergingOptions: (state) => state.mergingOptions,
      mergeByProperties: (state) => state.mergeByProperties,
      merging: (state) => state.merging,

      reportName: (state) => state.reportName,
      subId: (state) => state.subId,
    }),
    isAdXHealthReport() {
      return this.reportKey === PATHS.REPORTS.ADX_HEALTH
    },
    isNetworkHealthReport() {
      return this.reportKey === PATHS.REPORTS.NETWORK_HEALTH
    },
    isYieldPartnerReport() {
      return this.reportKey === PATHS.REPORTS.YIELD_PARTNER
    },
    groupedBy: {
      get() {
        const { currentGroupByProperties } = this

        return currentGroupByProperties || []
      },
      set(groupByProperties) {
        const { reportKey } = this

        this.$store.commit('tools/isCurrentlyGrouping', true)
        this.$store.commit('tools/grouping', { groupByProperties, reportKey })

        return groupByProperties
      },
    },
    mergedBy: {
      get() {
        const { currentMergeByProperties } = this

        return currentMergeByProperties || []
      },
      set(mergeByProperties) {
        const { reportKey } = this

        this.$store.commit('tools/isCurrentlyMerging', true)
        this.$store.commit('tools/merging', { mergeByProperties, reportKey })

        return mergeByProperties
      },
    },
  },
  watch: {
    reportKey(newValue, oldValue) {
      return newValue !== oldValue
    },
  },
}
</script>

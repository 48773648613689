<template>
  <v-container
    class="delivery-table-filter px-3"
    fluid
  >
    <v-row class="mt-1">
      <merge-tool />
    </v-row>

    <v-divider class="mt-6" />

    <v-row class="mt-3">
      <group-tool />
    </v-row>

    <v-divider class="mt-6" />

    <v-row>
      <v-col>
        <v-row>
          <v-card-subtitle
            class="subtitle-1 font-weight-medium darkestblue--text mt-6"
          >
            Filter
          </v-card-subtitle>
        </v-row>

        <v-row
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="topLevelAdUnitIds"
              label="Top-Level Ad Unit Ids"
              :options="topLevelAdUnitIdsFilterValues"
              type="strict"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="topLevelAdUnitNames"
              label="Top-Level Ad Unit Names"
              :options="topLevelAdUnitNamesFilterValues"
              type="textual"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="advertiserName"
              label="Advertiser"
              :options="advertiserFilterValues"
              type="textual"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="orderName"
              label="Order"
              :options="orderFilterValues"
              type="textual"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="lineItemName"
              label="Line Item"
              :options="lineItemFilterValues"
              type="textual"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="isPacingReport"
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="status"
              label="Status"
              :options="statusFilterValues"
              type="textual"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
          wrap
        >
          <v-col class="pa-0">
            <text-input
              filter-key="isMissingCreatives"
              label="Missing Creatives"
              :options="booleanOptionsAlt"
              type="boolean"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="creativeSizes"
              label="Creative Sizes"
              :options="creativeSizesFilterValues"
              type="strict"
            />
          </v-col>
        </v-row>

        <date-input
          class="px-4"
          filter-key="startDate"
          label="Start Date"
        />

        <date-input
          class="px-4"
          filter-key="endDate"
          label="End Date"
        />

        <v-row
          v-if="isPacingReport"
          class="px-4"
          wrap
        >
          <v-col class="pa-0">
            <text-input
              filter-key="priority"
              label="Priority"
              :options="priorityFilterValues"
              type="textual"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="isDiscrepancyReport"
          class="px-4"
          wrap
        >
          <v-col class="pa-0">
            <text-input
              filter-key="gamPriority"
              label="Priority"
              :options="gamPriorityFilterValues"
              type="textual"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="isPacingReport"
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="delivered"
              label="Delivered To Date"
              :options="[]"
              type="numerical"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="impressionsYesterday"
              :disabled="true"
              :readonly="true"
              label="Impressions Yesterday"
              :options="[]"
              type="numerical"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="isPacingReport"
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="clicks"
              label="Clicks"
              :options="[]"
              type="numerical"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="ctr"
              label="Click-Through Rate"
              :options="indicatorFilterValues"
              type="indicator"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
          wrap
        >
          <v-col class="pa-0">
            <text-input
              filter-key="osi"
              label="OSI"
              :options="osiFilterValues"
              type="indicator"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="rate"
              label="Rate"
              :options="[]"
              type="numerical"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
          wrap
        >
          <v-col class="pa-0">
            <text-input
              filter-key="salespersonName"
              label="Sales Person"
              :options="salespersonNameFilterValues"
              type="textual"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
          wrap
        >
          <v-col class="pa-0">
            <text-input
              filter-key="traffickerName"
              label="Trafficker"
              :options="traffickerNameFilterValues"
              type="textual"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
          wrap
        >
          <v-col class="pa-0">
            <text-input
              filter-key="secondaryTraffickerNames"
              label="Secondary Traffickers"
              :options="secondaryTraffickerNamesFilterValues"
              type="textual"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="isDiscrepancyReport"
          class="px-4"
          wrap
        >
          <v-col class="pa-0">
            <text-input
              filter-key="discrepancyPercentage"
              label="Discrepancy"
              :options="discrepancyFilterValues"
              type="indicator"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="isPacingReport"
          class="px-4"
          wrap
        >
          <v-col class="pa-0">
            <text-input
              filter-key="customFields"
              label="Custom Fields"
              :options="customFieldFilterValues"
              type="textual"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="allRevenue"
              label="Revenue"
              :options="[]"
              type="numerical"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="potentialLostRevenue"
              label="Potential Lost Revenue"
              :options="[]"
              type="numerical"
            />
          </v-col>
        </v-row>

        <v-row
          class="px-4"
        >
          <v-col class="pa-0">
            <text-input
              filter-key="potentialLostRevenuePercentage"
              label="Potential Lost Revenue %"
              :options="[]"
              type="numerical"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import DateInput from 'src/tools/components/filter/DateInput'
// import NumberInput from 'src/tools/components/filter/NumberInput'
import TextInput from 'src/tools/components/filter/TextInput'

import GroupTool from 'src/tools/components/filter/GroupTool'
import MergeTool from 'src/tools/components/filter/MergeTool'

const { mapGetters, mapState } = createNamespacedHelpers('tools')
const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')

export default {
  name: 'DeliveryFilterForm',
  components: {
    DateInput,
    GroupTool,
    MergeTool,
    // NumberInput,
    TextInput,
  },
  computed: {
    ...mapAuthGetters([
      'isUserInternalAdmin',
    ]),
    ...mapGetters([
      'advertiserFilterValues',
      'creativeSizesFilterValues',
      'customFieldFilterValues',
      'discrepancyFilterValues',
      'gamPriorityFilterValues',
      'indicatorFilterValues',
      'lineItemFilterValues',
      'orderFilterValues',
      'osiFilterValues',
      'priorityFilterValues',
      'reportKey',
      'salespersonNameFilterValues',
      'secondaryTraffickerNamesFilterValues',
      'statusFilterValues',
      'traffickerNameFilterValues',
      'topLevelAdUnitIdsFilterValues',
      'topLevelAdUnitNamesFilterValues',
    ]),
    ...mapState({
      booleanOptionsAlt: (state) => state.booleanOptionsAlt,
      filterData: (state) => state.filterData,
      subId: (state) => state.subId,
    }),
    isPacingReport() {
      return this.reportKey === 'pacing'
    },
    isDiscrepancyReport() {
      return this.reportKey === 'discrepancy'
    },
  },
  watch: {
    reportKey(newValue, oldValue) {
      return newValue !== oldValue
    },
  },
}
</script>

<template>
  <v-container
    class="project-table-filter"
    fluid
  >
    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="projectName"
          label="Project Name"
          :options="projectNameFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import TextInput from 'src/tools/components/filter/TextInput'

const { mapGetters } = createNamespacedHelpers('tools')

export default {
  name: 'ProjectFilterForm',
  components: {
    TextInput,
  },
  computed: {
    ...mapGetters([
      'projectNameFilterValues',
    ]),
  },
}
</script>

<template>
  <v-row wrap>
    <v-col
      class="col-auto pl-0"
    >
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            color="darkblue"
            dark
            outlined
            v-on="on"
            @click="!showDateOperatorsMenu"
          >
            {{ dateOperatorText }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group v-model="showDateOperatorsMenu">
            <v-list-item
              v-for="item in filterOperators.date"
              :key="item.value"
              @click="setDateRangeOperator(item)"
            >
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-col>

    <v-col
      v-if="dateOperator === 'range'"
      class="pa-0"
    >
      <v-menu
        :ref="[`${filterKey}Range`]"
        v-model="dateRangeMenu"
        :return-value="dateValue"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="dateRangeText"
            :label="`${label} Range`"
            readonly
            :rules="dateRules"
            v-on="on"
          />
        </template>

        <v-date-picker
          v-model="dateValue"
          no-title
          scrollable
          range
        >
          <!-- eslint-disable-next-line vue/html-self-closing -->
          <v-spacer></v-spacer>
          <v-btn
            text
            color="redorange"
            @click="resetDate"
          >
            Reset
          </v-btn>

          <v-btn
            text
            color="coolgray"
            @click="dateRangeMenu = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            @click="
              $refs[`${filterKey}Range`].save(dateValue)
            "
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>

    <v-col
      v-else
      class="pa-0"
    >
      <v-menu
        :ref="filterKey"
        v-model="dateMenu"
        :return-value="dateValue"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="dateText"
            :label="label"
            readonly
            v-on="on"
          />
        </template>

        <v-date-picker
          v-model="dateValue"
          no-title
          scrollable
        >
          <!-- eslint-disable-next-line vue/html-self-closing -->
          <v-spacer></v-spacer>

          <v-btn
            text
            color="redorange"
            @click="resetDate"
          >
            Reset
          </v-btn>

          <v-btn
            text
            color="coolgray"
            @click="dateMenu = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            @click="$refs[filterKey].save(dateValue)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import dates from 'src/utils/dates'
import operators from 'src/utils/operators'

const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  name: 'DateInput',
  props: {
    filterKey: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dateMenu: false,
      dateRangeMenu: false,
      filterOperators: operators,
      showDateOperatorsMenu: false,
    }
  },
  computed: {
    ...mapGetters(['currentFilterData', 'reportKey']),
    ...mapState({
      filterData: (state) => state.filterData,
      reportName: (state) => state.reportName,
    }),
    date() {
      const { filterKey, reportKey } = this

      return this.filterData[reportKey][filterKey]
    },
    dateValue: {
      get() {
        const { date, dateOperator } = this

        if (dateOperator === 'range') {
          return date.value[0] || []
        }

        return date.value[0] || null
      },
      set(newValue) {
        const { filterKey, reportKey } = this

        this.$store.commit('tools/isCurrentlyUpdating', true)

        this.filterData[reportKey][filterKey].value = [newValue]
      },
    },
    dateOperator: {
      get() {
        const { date } = this
        const { operator } = date

        return operator || operators.date[0].value
      },
      set(newValue) {
        const { filterKey, reportKey } = this
        const isRange = newValue === 'range'

        this.filterData[reportKey][filterKey].value = isRange ? [[]] : [null]
        this.filterData[reportKey][filterKey].operator = newValue
      },
    },
    dateOperatorText() {
      const { dateOperator } = this
      const result = operators.date.find((op) => op.value === dateOperator)

      return result.text
    },
    dateError() {
      const { dateRules } = this

      return dateRules.length && dateRules[0]() !== true
    },
    dateRules() {
      const { currentFilterData } = this

      if (!currentFilterData) return []

      const { dateValue, dateOperator } = currentFilterData
      const success = [() => true]

      if (!dateOperator || dateOperator !== 'range') {
        return success
      }

      if (dateValue.length > 1) {
        const [start, end] = dateValue
        const valid = dates.isDateLte(start, end, undefined)
        const errorMessage = 'Invalid Date Range'

        return [() => valid || errorMessage]
      }

      return success
    },
    dateRangeText() {
      const { dateError, dateOperator, dateValue } = this

      if (dateOperator !== 'range' || dateError) return ''

      return `${dateValue[0]} to ${dateValue[1]}`
    },
    dateText() {
      const { dateOperator, dateValue } = this

      if (!dateValue || dateOperator === 'range') return ''

      return dates.getFormattedDate(dateValue, 'dddd, MMMM DD, YYYY')
    },
  },
  methods: {
    resetDate() {
      const { filterKey } = this
      const [defaultOperator] = operators.date

      this.currentFilterData[filterKey].operator = defaultOperator.value
      this.currentFilterData[filterKey].value = []
    },
    setDateRangeOperator(option) {
      const { currentFilterData, filterKey, reportKey } = this

      if (!option) return

      const newFilterData = { ...currentFilterData }
      const isRange = option.value === 'range'

      newFilterData[filterKey].value = isRange ? [[]] : [null]
      newFilterData[filterKey].operator = option.value

      this.$store.commit('tools/filterData', {
        reportKey,
        filterData: { [reportKey]: newFilterData },
      })
    },
  },
}
</script>

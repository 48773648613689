<template>
  <v-container class="pa-0">
    <date-input
      class="px-4"
      filter-key="date"
      label="Date"
    />

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="topLevelAdUnitCode"
          label="Top-Level Ad Unit Id"
          :options="topLevelAdUnitCodeFilterValues"
          type="strict"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="topLevelAdUnitName"
          label="Top-Level Ad Unit Name"
          :options="topLevelAdUnitNameFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="deviceCategory"
          label="Device Category"
          :options="deviceCategoryFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="inventorySize"
          label="Inventory Size"
          :options="inventorySizeFilterValues"
          type="strict"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="adRequests"
          label="Ad Requests"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="matchedRequests"
          label="Matched Requests"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="coverage"
          label="Coverage"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="adImpressions"
          label="Impressions"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="adImpressionPerc"
          label="Impression Perc"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="adEcpm"
          label="eCPM"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="estimatedRevenue"
          label="Estimated Revenue"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import DateInput from 'src/tools/components/filter/DateInput'
import TextInput from 'src/tools/components/filter/TextInput'

const { mapGetters, mapState } = createNamespacedHelpers('tools')
const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')

export default {
  name: 'HealthAdxFilterForm',
  components: {
    DateInput,
    TextInput,
  },
  computed: {
    ...mapAuthGetters([
      'isUserInternalAdmin',
    ]),
    ...mapGetters([
      'reportKey',

      'currentGroupByProperties',
      'currentMergeByProperties',

      'topLevelAdUnitCodeFilterValues',
      'topLevelAdUnitNameFilterValues',
      'deviceCategoryFilterValues',
      'inventorySizeFilterValues',
    ]),
    ...mapState({
      booleanOptions: (state) => state.booleanOptions,
      filterData: (state) => state.filterData,

      groupingOptions: (state) => state.groupingOptions,
      groupByProperties: (state) => state.groupByProperties,
      grouping: (state) => state.grouping,

      mergingOptions: (state) => state.mergingOptions,
      mergeByProperties: (state) => state.mergeByProperties,
      merging: (state) => state.merging,

      reportName: (state) => state.reportName,
      subId: (state) => state.subId,
    }),
    groupedBy: {
      get() {
        const { currentGroupByProperties } = this

        return currentGroupByProperties || []
      },
      set(groupByProperties) {
        const { reportKey } = this

        this.$store.commit('tools/isCurrentlyGrouping', true)
        this.$store.commit('tools/grouping', { groupByProperties, reportKey })

        return groupByProperties
      },
    },
    mergedBy: {
      get() {
        const { currentMergeByProperties } = this

        return currentMergeByProperties || []
      },
      set(mergeByProperties) {
        const { reportKey } = this

        this.$store.commit('tools/isCurrentlyMerging', true)
        this.$store.commit('tools/merging', { mergeByProperties, reportKey })

        return mergeByProperties
      },
    },
  },
  watch: {
    reportKey(newValue, oldValue) {
      return newValue !== oldValue
    },
  },
}
</script>

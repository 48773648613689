<template>
  <v-container class="pa-0">
    <date-input
      class="px-4"
      filter-key="date"
      label="Date"
    />

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="demandChannelCode"
          label="Demand Channel Id"
          :options="demandChannelCodeFilterValues"
          type="strict"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="isYieldReport"
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="demandChannelName"
          label="Demand Channel"
          :options="demandChannelNameFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="isYieldReport"
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="yieldPartner"
          label="Yield Partner"
          :options="yieldPartnerFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="yieldImpressions"
          :disabled="true"
          :readonly="true"
          label="Impressions"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="yieldEstimatedCPM"
          :disabled="true"
          :readonly="true"
          label="eCPM"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="yieldEstimatedRevenue"
          :disabled="true"
          :readonly="true"
          label="Revenue"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import PATHS from 'src/router/paths'

import DateInput from 'src/tools/components/filter/DateInput'
// import NumberInput from 'src/tools/components/filter/NumberInput'
import TextInput from 'src/tools/components/filter/TextInput'

const { mapGetters, mapState } = createNamespacedHelpers('tools')
const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')

export default {
  name: 'YieldFilterForm',
  components: {
    // NumberInput,
    DateInput,
    TextInput,
  },
  computed: {
    ...mapAuthGetters([
      'isUserInternalAdmin',
    ]),
    ...mapGetters([
      'reportKey',

      'currentGroupByProperties',
      'currentMergeByProperties',

      'demandChannelCodeFilterValues',
      'demandChannelNameFilterValues',
      'yieldPartnerFilterValues',
    ]),
    ...mapState({
      booleanOptions: (state) => state.booleanOptions,
      filterData: (state) => state.filterData,

      groupingOptions: (state) => state.groupingOptions,
      groupByProperties: (state) => state.groupByProperties,
      grouping: (state) => state.grouping,

      mergingOptions: (state) => state.mergingOptions,
      mergeByProperties: (state) => state.mergeByProperties,
      merging: (state) => state.merging,

      reportName: (state) => state.reportName,
      subId: (state) => state.subId,
    }),
    isYieldReport() {
      return [PATHS.REPORTS.YIELD_PARTNER].indexOf(this.reportKey) > -1
    },
    groupedBy: {
      get() {
        const { currentGroupByProperties } = this

        return currentGroupByProperties || []
      },
      set(groupByProperties) {
        const { reportKey } = this

        this.$store.commit('tools/isCurrentlyGrouping', true)
        this.$store.commit('tools/grouping', { groupByProperties, reportKey })

        return groupByProperties
      },
    },
    mergedBy: {
      get() {
        const { currentMergeByProperties } = this

        return currentMergeByProperties || []
      },
      set(mergeByProperties) {
        const { reportKey } = this

        this.$store.commit('tools/isCurrentlyMerging', true)
        this.$store.commit('tools/merging', { mergeByProperties, reportKey })

        return mergeByProperties
      },
    },
  },
  watch: {
    reportKey(newValue, oldValue) {
      return newValue !== oldValue
    },
  },
}
</script>

<template>
  <v-col>
    <v-row>
      <v-card-subtitle
        class="subtitle-1 font-weight-medium darkestblue--text"
      >
        <v-row>
          <v-col>
            <span>
              Group
            </span>

            <v-icon
              small
              :class="`ml-1 ${showInfo ? 'primary' : 'midgray'}--text`"
              title="Instructions"
              @click="toggleInfo"
            >
              help
            </v-icon>
          </v-col>
        </v-row>

        <v-row
          v-if="showInfo"
          class="mt-0"
        >
          <v-col>
            <p class="caption coolgray--text">
              Break rows into groups of related items.
            </p>

            <p class="caption coolgray--text">
              <b>Example</b> Selecting Order will create an order-level breakdown
              rendering a table per order.
            </p>

            <p class="mb-0 caption coolgray--text">
              <b>Hint</b> Combine dimensions below for even deeper data drilling.
            </p>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-row>

    <v-row class="px-4">
      <v-select
        v-model="groupedBy"
        label="Property"
        :items="currentGroupingOptions"
        dense
        multiple
        clearable
      >
        <template v-slot:prepend-item>
          <v-list-item>
            <v-list-item-content
              ripple
              @click.stop="toggleSelectAll('select')"
            >
              <v-list-item-title class="align-center row">
                <v-col class="col-1">
                  <v-icon :color="hasSelectedAll ? 'primary' : 'coolgray'">
                    {{ selectAllIcon }}
                  </v-icon>
                </v-col>

                <v-col class="pl-2 coolgray--text font-weight-medium text-wrap body-2">
                  <span>Select All</span>
                </v-col>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content
              ripple
              @click.stop="toggleSelectAll('toggle')"
            >
              <v-list-item-title class="align-center row">
                <v-col class="col-1">
                  <v-icon
                    color="coolgray"
                    ripple
                  >
                    {{ toggleAllIcon }}
                  </v-icon>
                </v-col>

                <v-col class="pl-2 coolgray--text font-weight-medium text-wrap body-2">
                  <span>Toggle All</span>
                </v-col>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mt-2" />
        </template>
      </v-select>
    </v-row>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  name: 'GroupTool',
  data() {
    return {
      showInfo: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentGroupByProperties',
      'currentGrouping',
      'currentGroupingOptions',
      'reportKey',
    ]),
    ...mapState({
      isCurrentlyGrouping: (state) => state.isCurrentlyGrouping,
    }),

    groupedBy: {
      get() {
        return (this.isCurrentlyGrouping && this.currentGrouping) || this.currentGroupByProperties
      },
      set(groupByProperties) {
        const { reportKey } = this

        this.$store.commit('tools/isCurrentlyGrouping', true)
        this.$store.commit('tools/grouping', { groupByProperties, reportKey })

        return groupByProperties
      },
    },
    hasSelectedAll() {
      const { currentGroupingOptions, groupedBy } = this
      const totalSelected = groupedBy.length
      const totalOptions = currentGroupingOptions.length
      const hasSelectedAll = totalSelected === totalOptions

      return totalOptions > 0 && hasSelectedAll
    },
    hasSelectedSome() {
      return this.groupedBy.length > 0 && !this.hasSelectedAll
    },
    selectAllIcon() {
      return (this.hasSelectedAll && 'check_box') || 'check_box_outline_blank'
    },
    toggleAllIcon() {
      return 'fact_check'
    },
  },
  methods: {
    toggleInfo() {
      this.showInfo = !this.showInfo
    },
    toggleSelectAll(selectOrToggle) {
      const { hasSelectedAll } = this
      const isToggling = selectOrToggle === 'toggle'

      const selectAll = () => {
        this.groupedBy = this.currentGroupingOptions.map((option) => option.value)
      }
      const unselectAll = () => {
        this.groupedBy = []
      }
      const toggleAll = () => {
        const filterSelected = (option) => !this.groupedBy.includes(option.value)
        this.groupedBy = this.currentGroupingOptions.filter(filterSelected).map((option) => option.value)
      }

      if (isToggling) {
        toggleAll()
      } else if (hasSelectedAll) {
        unselectAll()
      } else {
        selectAll()
      }
    },
  },
}
</script>

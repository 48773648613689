<template>
  <v-dialog
    v-model="displayFilter"
    class="filter-content"
    max-width="660"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
          <v-badge
            color="reds"
            avatar
            bordered
            dot
            overlap
            :value="totalAppliedDataTools"
          >
            <template
              v-slot:badge
            >
              {{ totalAppliedDataTools }}
            </template>

            <v-btn
              color="coolgray"
              icon
              small
              :ripple="false"
              v-bind="tooltipAttrs"
              v-on="tooltipOn"
            >
              <v-icon
                color="coolgray"
                :disabled="!showFilterBar"
                v-on="on"
              >
                filter_list
              </v-icon>
            </v-btn>
          </v-badge>
        </template>

        <span>Data Tools</span>
      </v-tooltip>
    </template>

    <v-card class="pb-12">
      <v-card-title class="title pl-10 py-6">
        <v-row>
          <v-col
            class="flex-grow py-0 darkestblue--text"
          >
            Tools
          </v-col>

          <v-spacer />

          <v-col
            class="flex-shrink-1 col-2 py-0 text-right"
          >
            <v-btn
              :ripple="false"
              icon
              @click.stop="displayFilter = false"
            >
              <v-icon color="brightblue">
                close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="px-6 pb-6">
        <slot name="filter-controls" />

        <v-row
          align="center"
          justify="end"
          class="pl-6 pr-6 pt-6"
        >
          <v-spacer />

          <v-btn
            text
            small
            color="error"
            class="text-none"
            :loading="resettingFilter"
            @click="onReset(true)"
          >
            Reset
            <template v-slot:loader>
              <span class="custom-loader">
                <v-progress-circular
                  value="25"
                  size="20"
                />
              </span>
            </template>
          </v-btn>

          <v-btn
            small
            primary
            dark
            color="brightblue"
            class="elevation-0"
            :loading="applyingFilter"
            @click="onApply(true)"
          >
            Apply
            <template v-slot:loader>
              <span class="custom-loader">
                <v-progress-circular
                  value="25"
                  size="20"
                />
              </span>
            </template>
          </v-btn>
        </v-row>

        <slot name="saved-filters" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import render from 'src/utils/render'

const { mapGetters: mapLayoutGetters } = createNamespacedHelpers('layout')
const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  name: 'FilterForm',
  data() {
    return {
      showAdvancedTools: false,
    }
  },
  computed: {
    displayFilter: {
      get() {
        return this.$store.state.tools.displayFilter
      },
      set(value) {
        this.$store.dispatch('tools/displayFilter', value)
      },
    },
    displayAdvancedTools: {
      get() {
        return this.$store.state.tools.displayAdvancedTools
      },
      set(value) {
        this.$store.dispatch('tools/toggleAdvancedTools', value)
      },
    },
    ...mapLayoutGetters(['showFilterBar']),
    ...mapGetters(['currentItems', 'currentSummary', 'reportKey', 'totalAppliedDataTools']),
    ...mapState({
      applyingFilter: (state) => state.applyingFilter,
      filterData: (state) => state.filterData,
      items: (state) => state.items,
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
      resettingFilter: (state) => state.resettingFilter,
      subId: (state) => state.subId,
    }),
  },
  watch: {
    displayFilter(newValue) {
      this.$emit('on-toggle-filters', newValue)
    },
  },
  methods: {
    toggleAdvancedTools() {
      this.displayAdvancedTools = !this.displayAdvancedTools
    },
    onReset(emitEvent = false) {
      render.ensureViewIsUpdatedAndRenderedImmediately(async () => {
        await this.$store.commit('tools/resettingFilter', true)

        if (emitEvent) {
          await this.$store.dispatch('tools/resetFilters')

          this.$store.dispatch('tools/displayFilter', false)
        }

        this.$store.commit('tools/resettingFilter', false)
      })
    },
    onApply(emitEvent = false) {
      render.ensureViewIsUpdatedAndRenderedImmediately(async () => {
        await this.$store.commit('tools/applyingFilter', true)

        if (emitEvent) {
          const { currentItems, reportId, reportKey, reportName, subId } = this
          // const useSummaryItems = reportName === 'pacing' && currentSummary
          // const items = useSummaryItems ? currentSummary.items : currentItems
          const report = { items: currentItems, reportKey, reportId, reportName, subId }

          await this.$store.dispatch('tools/applyFilters', report)

          this.$store.dispatch('tools/displayFilter', false)
        }

        this.$store.commit('tools/applyingFilter', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.filter-content {
  background: $color-monochrome-white;
  border-radius: 6px;
  width: 100%;
}

.controls-container {
  background: $color-monochrome-white;
  padding: 24px;
  display: flex;

  @media screen and (max-width: $vuetify-md-max-size) {
    flex-direction: column;

    .filter-label {
      padding-top: 0;
      padding-bottom: 10px;
    }
  }
}

.filter-label {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.21px;
  line-height: 11px;
}

.controls {
  flex: 2;
  position: relative;
}

</style>

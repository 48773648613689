<template>
  <v-container
    class="org-table-filter"
    fluid
  >
    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="billingCode"
          label="Billing Code"
          :options="billingCodeFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="orgName"
          label="Name"
          :options="orgNameFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="networkCode"
          label="Network Code"
          :options="networkCodeFilterValues"
          type="strict"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="isInternalUseOnly"
          label="Internal Use Only"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="hasDiscrepancyReport"
          label="Discrepancy"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="hasHealthAdxReport"
          label="AdX Health"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="hasHealthNetworkReport"
          label="Network Health"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="hasYieldPartnerReport"
          label="Yield"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="hasPacingReport"
          label="Pacing"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="hasTimesheetReport"
          label="Timesheet"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import TextInput from 'src/tools/components/filter/TextInput'

const { mapState: mapInternalState } = createNamespacedHelpers('internal')
const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  name: 'OrgFilterForm',
  components: {
    TextInput,
  },
  computed: {
    ...mapGetters([
      'billingCodeFilterValues',
      'hasDiscrepancyReportFilterValues',
      'hasPacingReportFilterValues',
      'hasTimesheetReportFilterValues',
      'isInternalUseOnlyFilterValues',
      'networkCodeFilterValues',
      'orgNameFilterValues',
    ]),
    ...mapInternalState({
      organizations: (state) => state.organizations,
      projects: (state) => state.projects,
      timeTypes: (state) => state.timeTypes,
    }),
    ...mapState({
      booleanOptions: (state) => state.booleanOptions,
      filterData: (state) => state.filterData,
      reportName: (state) => state.reportName,
    }),
  },
}
</script>

<template>
  <v-col>
    <v-row>
      <v-card-subtitle
        class="subtitle-1 font-weight-medium darkestblue--text"
      >
        <v-row>
          <v-col>
            <span>
              Merge
            </span>

            <v-icon
              small
              :class="`ml-1 ${showInfo ? 'primary' : 'midgray'}--text`"
              title="Instructions"
              @click="toggleInfo"
            >
              help
            </v-icon>
          </v-col>
        </v-row>

        <v-row
          v-if="showInfo"
          class="mt-0"
        >
          <v-col>
            <p class="caption coolgray--text">
              Merge rows to summarize metrics across items.
            </p>

            <p class="caption coolgray--text">
              <b>Example</b> Select Advertiser, Order, and Line Item to merge items with
              all the same dimensions except those identified here as ignorable.
            </p>

            <p class="mb-0 caption coolgray--text">
              <b>Hint</b> Select all options to combine all data into one row of summarized metrics.
            </p>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-row>

    <v-row class="px-4">
      <v-select
        v-model="mergedBy"
        label="Property"
        :items="currentMergingOptions"
        dense
        multiple
        clearable
      >
        <template v-slot:prepend-item>
          <v-list-item>
            <v-list-item-content
              ripple
              @click.stop="toggleSelectAll('select')"
            >
              <v-list-item-title class="align-center row">
                <v-col class="col-1">
                  <v-icon :color="hasSelectedAll ? 'primary' : 'coolgray'">
                    {{ selectAllIcon }}
                  </v-icon>
                </v-col>

                <v-col class="pl-2 coolgray--text font-weight-medium text-wrap body-2 cursor-pointer">
                  <span>Select All</span>
                </v-col>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content
              ripple
              @click.stop="toggleSelectAll('toggle')"
            >
              <v-list-item-title class="align-center row">
                <v-col class="col-1">
                  <v-icon color="coolgray">
                    {{ toggleAllIcon }}
                  </v-icon>
                </v-col>

                <v-col class="pl-2 coolgray--text font-weight-medium text-wrap body-2 cursor-pointer">
                  <span>Toggle All</span>
                </v-col>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mt-2" />
        </template>
      </v-select>
    </v-row>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  name: 'MergeTool',
  data() {
    return {
      showInfo: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentMergeByProperties',
      'currentMerging',
      'currentMergingOptions',
      'reportKey',
    ]),
    ...mapState({
      isCurrentlyMerging: (state) => state.isCurrentlyMerging,
    }),
    mergedBy: {
      get() {
        return (this.isCurrentlyMerging && this.currentMerging) || this.currentMergeByProperties
      },
      set(mergeByProperties) {
        const { reportKey } = this

        this.$store.commit('tools/merging', { mergeByProperties, reportKey })
        this.$store.commit('tools/isCurrentlyMerging', true)

        return mergeByProperties
      },
    },
    hasSelectedAll() {
      const { currentMergingOptions, mergedBy } = this
      const totalSelected = mergedBy.length
      const totalOptions = currentMergingOptions.length
      const hasSelectedAll = totalSelected === totalOptions

      return totalOptions > 0 && hasSelectedAll
    },
    hasSelectedSome() {
      return this.mergedBy.length > 0 && !this.hasSelectedAll
    },
    selectAllIcon() {
      return (this.hasSelectedAll && 'check_box') || 'check_box_outline_blank'
    },
    toggleAllIcon() {
      return 'fact_check'
    },
  },
  methods: {
    toggleInfo() {
      this.showInfo = !this.showInfo
    },
    toggleSelectAll(selectOrToggle) {
      const { hasSelectedAll } = this
      const isToggling = selectOrToggle === 'toggle'

      const selectAll = () => {
        this.mergedBy = this.currentMergingOptions.map((option) => option.value)
      }
      const unselectAll = () => {
        this.mergedBy = []
      }
      const toggleAll = () => {
        const filterSelected = (option) => !this.mergedBy.includes(option.value)
        this.mergedBy = this.currentMergingOptions.filter(filterSelected).map((option) => option.value)
      }

      if (isToggling) {
        toggleAll()
      } else if (hasSelectedAll) {
        unselectAll()
      } else {
        selectAll()
      }
    },
  },
}
</script>

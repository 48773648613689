<template>
  <v-container
    v-if="isInternalUser"
    class="internal-table-filter"
    fluid
  >
    <org-filter-form v-if="reportKey === 'organization'" />

    <person-filter-form v-if="reportKey === 'person'" />

    <project-filter-form v-if="reportKey === 'project'" />

    <time-filter-form v-if="reportKey === 'time'" />
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import OrgFilterForm from './OrgFilterForm'
import PersonFilterForm from './PersonFilterForm'
import ProjectFilterForm from './ProjectFilterForm'
import TimeFilterForm from './TimeFilterForm'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapGetters } = createNamespacedHelpers('tools')

export default {
  name: 'InternalFilterForm',
  components: {
    OrgFilterForm,
    PersonFilterForm,
    ProjectFilterForm,
    TimeFilterForm,
  },
  computed: {
    ...mapAuthGetters(['isInternalUser']),
    ...mapGetters(['reportKey']),
  },
}
</script>

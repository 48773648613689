<template>
  <v-container
    class="time-table-filter"
    fluid
  >
    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="billingCode"
          label="Billing Code"
          :options="billingCodeFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="clientName"
          label="Organization Name"
          :options="orgNameFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="personName"
          label="Person"
          :options="internals"
          type="textual"
        />
      </v-col>
    </v-row>

    <date-input
      filter-key="timeEntryDate"
      label="Time Entry Date"
    />

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="timeClassificationName"
          label="Time Types"
          :options="timeTypeFilterValues"
          type="strict"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="projectName"
          label="Project Name"
          :options="projectNameFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="workNote"
          label="Notes"
          :options="workNoteFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import DateInput from 'src/tools/components/filter/DateInput'
import TextInput from 'src/tools/components/filter/TextInput'

const {
  mapGetters: mapInternalGetters,
  mapState: mapInternalState,
} = createNamespacedHelpers('internal')
const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  name: 'TimeFilterForm',
  components: {
    DateInput,
    TextInput,
  },
  computed: {
    ...mapInternalGetters([
      'humans',
      'internals',
    ]),
    ...mapGetters([
      'billingCodeFilterValues',
      'orgNameFilterValues',
      'workNoteFilterValues',
      'projectNameFilterValues',
      'timeTypeFilterValues',
    ]),
    ...mapInternalState({
      // Uses entries to get all entries on the page despite reportName.
      entries: (state) => state.entries,
      organizations: (state) => state.organizations,
      projects: (state) => state.projects,
      timeTypes: (state) => state.timeTypes,
    }),
    ...mapState({
      booleanOptions: (state) => state.booleanOptions,
      filterData: (state) => state.filterData,
      reportName: (state) => state.reportName,
    }),
  },
}
</script>
